import React, { useContext } from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Container, Box } from '@chakra-ui/react'
import { H1 } from '../theme/Headings'
import { spacing, breakpoints } from '../theme/themeAlopex'
import ColorContext from '../context/ColorContext'

const NotFoundPage = () => {
  const { color } = useContext(ColorContext)

    return (
      <Layout>
        <SEO title="404: Not found" />
        <Container maxWidth={breakpoints.xl} pt={spacing.header} mb={spacing.groups}>
          <H1 variant="pageHeader" color={color.alpha}>404: Not Found</H1>
          <Box as="p" textAlign="center">You just hit a route that doesn&#39;t exist... the sadness.</Box>
        </Container>
      </Layout>
    )
  }

export default NotFoundPage